.banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 32%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*filter: grayscale(90%);*/
}

.banner h1 {
    font-size: 1.5rem;
    font-weight: bold;
}
