
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.spinner {
  animation: spin infinite 1s linear;

  /*You can increase or decrease the timer (5s) to
   increase or decrease the speed of the spinner*/
}

.Btn {
    background: #fff;
    color: #000;
    border: 1px solid #fff;
}

.Btn:focus {
    border: 1px solid #f1f1f1;
}

.ModalNavigation {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1500;
}

a {
    color: #000;
    text-decoration: none;
    display: block;
}

.CloseNavigation {
    position: absolute;
    right: 10px;
    background: transparent;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-green {
    color: #009f60;
}

.bg-green {
    background: #009f60 !important;
    color: #fff;
}

.text-orange {
    color: #f78000;
}

.bg-orange {
    background: #f78000 !important;
    color: #fff;
}

.ContactUs {
    position: fixed;
    top: 1px;
    right: 5% !important;
    font-size: 1.2rem;
    z-index: 10000;
}


.bg-vehicle {
    background: #f1f1f1;
}

#Banner{
    background: #fff;
    border-radius: 100px;
}

#Brand {
    position: relative;
    top: -30px;
    z-index: 2000;
}

.ForSale {
    color: #fff;
    top: 2px;
    right: 2px;
}