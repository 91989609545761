.cover {
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: 15px;
    background-color: #f1f1f1;
    border-radius: 5px;
}

.Badge {
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    padding: 0px 6px;
    margin-right: 10px;
    margin-bottom: 7px;
    color: rgb(0, 126, 120);
    font-weight: 700;
    font-size: 11px;
    line-height: 1.8rem;
    display: inline-block;
}

.react-datepicker-wrapper {
    width: 100%;
}
