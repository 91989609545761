.SearchForm {
}

.Filter {
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
    padding: 8px;
    margin-bottom: 15px;
}

